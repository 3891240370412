'use client';

import { useCurrentInstance } from '@/app/api/useCurrent';
import { getParams } from '@/app/utils/url';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { usePathname } from 'next/navigation';
import posthog from 'posthog-js';
import React from 'react';

export interface AnalyticsContextType {
  page: (
    category?: string | Record<string, unknown>,
    name?: string | Record<string, unknown>,
    properties?: any,
  ) => void;
  track: (event: string, properties?: any) => void;
  identify: (userId: string, traits?: any) => void;
}

const noop = () => {
  return;
};

export const AnalyticsContext = React.createContext<AnalyticsContextType>({
  page: noop,
  track: noop,
  identify: noop,
});

export const AnalyticsProvider = ({ children }: React.PropsWithChildren) => {
  const pathname = usePathname();
  const { application: applicationId, instance: instanceId } = getParams(
    pathname!,
  );

  const currentInstance = useCurrentInstance();

  const analytics = React.useMemo(() => {
    if (!process.env.NEXT_PUBLIC_SEGMENT_WRITEKEY) {
      return {
        page: noop,
        track: noop,
        identify: noop,
      };
    }

    const segmentAnalyticsBrowser = AnalyticsBrowser.load(
      {
        writeKey: process.env.NEXT_PUBLIC_SEGMENT_WRITEKEY,
        cdnURL: 'https://scdn.clerk.com',
      },
      {
        integrations: {
          'Segment.io': {
            apiHost: 'segapi.clerk.com/v1',
            protocol: 'https',
          },
        },
      },
    );

    if (
      typeof window !== 'undefined' &&
      process.env.NEXT_PUBLIC_POSTHOG_KEY &&
      process.env.NEXT_PUBLIC_POSTHOG_HOST
    ) {
      posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY!, {
        // Use our proxied ingest path
        // https://github.com/clerk/clerk/blob/449aed74967539955adae072422e07cb0df759d1/next.config.mjs#L215
        api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
        ui_host: 'https://us.posthog.com',
        person_profiles: 'identified_only',
        // @ts-ignore posthog's types are made for the vanilla js version, not the react one, but this works fine
        segment: segmentAnalyticsBrowser,
        capture_pageview: false,
      });
    }

    if (typeof window !== 'undefined') {
      /**
       * When landing on clerk.com with a `gclid` search param the GCLID is
       * stored in a cookie. This middleware reads the cookie and adds the GCLID
       * to the properties of all Segment events so we can attribute these back
       * to Google campaigns.
       *
       * GCLID: https://support.google.com/google-ads/answer/9744275
       */
      void segmentAnalyticsBrowser.addSourceMiddleware(({ payload, next }) => {
        const gclidCookieName = 'gclid';
        // https://stackoverflow.com/a/25490531
        const gclid =
          document.cookie
            .match('(^|;)\\s*' + gclidCookieName + '\\s*=\\s*([^;]+)')
            ?.pop() || '';

        if (gclid) {
          payload.obj.properties = {
            gclid,
            ...payload.obj.properties,
          };
        }

        next(payload);
      });
    }

    const page = (
      category: string | Record<string, unknown>,
      name?: string | Record<string, unknown>,
      properties?: any,
    ) => {
      return segmentAnalyticsBrowser.page(category, name, properties);
    };

    const defaultTrackProperties = {
      ...(applicationId ? { applicationId } : {}),
      ...(instanceId ? { instanceId } : {}),
      ...(currentInstance?.environment_type
        ? { environmentType: currentInstance?.environment_type }
        : {}),
    };

    const track = (event: string, properties?: any) => {
      return segmentAnalyticsBrowser.track(event, {
        ...defaultTrackProperties,
        ...properties,
      });
    };

    const identify = (userId: string, traits?: any) => {
      return segmentAnalyticsBrowser.identify(userId, traits);
    };

    return {
      page,
      track,
      identify,
    };
  }, [currentInstance?.environment_type, applicationId, instanceId]);

  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
};

// Create an analytics hook that we can use with other components.
export const useAnalytics = (): AnalyticsContextType => {
  return React.useContext(AnalyticsContext);
};
