import React, { type ReactElement } from 'react';
import Head from 'next/head';
import { SignIn } from '@clerk/nextjs';
import { parseSearchParams } from '@utils/url';
import { inBrowser } from '@utils/browser';
import Image from 'next/image';

import { PublicLayout } from '@components/layouts/AppLayout/Layout';
import { Meteor } from '@/components/Meteor';
import { Footer } from '@/components/signInOrUp/footer';
import { Panel } from '@/components/signInOrUp/panel';
import glowAvif from '@components/signInOrUp/glow@q25r.avif';
import glowPng from '@components/signInOrUp/glow@tiny.png';

export default function CustomSignIn() {
  const title = 'Sign in | Clerk';
  const searchParams = parseSearchParams(
    inBrowser() ? window.location.search : '',
  );

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta property='og:title' content={title} key='title' />
      </Head>
      <div className='relative flex min-h-screen flex-col items-center overflow-hidden bg-gray-300 px-4 py-6 [:root:has(&)]:bg-gray-300'>
        <picture>
          <source srcSet={glowAvif.src} type='image/avif' />
          <source srcSet={glowPng.src} type='image/png' />
          <Image
            src={glowAvif}
            alt=''
            unoptimized
            priority
            className='pointer-events-none absolute left-1/2 top-1/2 z-10 h-[150vh] max-w-none -translate-x-1/2 -translate-y-1/2 select-none mix-blend-overlay'
          />
        </picture>

        <div className='relative flex w-full max-w-[25rem] flex-1 flex-col justify-center gap-y-6'>
          <main className='relative grid flex-1 content-center'>
            <div className='relative min-h-[calc(545/16*1rem)]'>
              <div className='relative z-20 [--animation-duration:250ms] [&>div]:animate-fade-in'>
                <SignIn
                  routing='path'
                  path='/sign-in'
                  // @ts-ignore
                  disabledStrategies={searchParams.getAll(
                    'disabled_strategies',
                  )}
                  appearance={{
                    layout: {
                      logoImageUrl: '/assets/logos/clerk_full_monochrome.svg',
                    },
                  }}
                />
              </div>

              <div className='pointer-events-none absolute inset-0'>
                <Panel className='absolute left-full top-16 ml-2 w-72 [mask-image:radial-gradient(circle_at_top_left,black,transparent_theme(spacing.72))]' />
                <Panel className='absolute right-full top-16 mr-2 w-72 -scale-x-100 [mask-image:radial-gradient(circle_at_top_left,black,transparent_theme(spacing.72))]' />
                <div className='absolute left-1/2 top-10 h-[9.25rem] w-[calc(100%+(10.125rem*2))] -translate-x-1/2 overflow-hidden'>
                  <div className='absolute left-1/2 h-[111px] w-[1216px] -translate-x-1/2'>
                    <svg fill='none' viewBox='0 0 1216 111' aria-hidden>
                      <path
                        className='stroke-black/5'
                        d='M0 110h347.654a7.999 7.999 0 0 0 5.696-2.383L455.9 3.633a8.001 8.001 0 0 1 5.696-2.383h292.308c2.141 0 4.192.858 5.696 2.383l102.55 103.984a7.999 7.999 0 0 0 5.696 2.383H1216'
                      />
                    </svg>
                    <Meteor
                      meteors={[
                        {
                          width: 1216,
                          height: 111,
                          path: 'M0 110h347.654a7.999 7.999 0 0 0 5.696-2.383L455.9 3.633a8.001 8.001 0 0 1 5.696-2.383h292.308c2.141 0 4.192.858 5.696 2.383l102.55 103.984a7.999 7.999 0 0 0 5.696 2.383H1216',
                          repeat: 6000,
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </main>

          <Footer />
        </div>
      </div>
    </>
  );
}

CustomSignIn.getLayout = function getLayout(page: ReactElement) {
  return <PublicLayout>{page}</PublicLayout>;
};
