import { HTTPError } from '@/app/utils/errors';
import { GetToken, ServerGetToken } from '@clerk/types';

type RequestOptions = {
  method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  path: string;
  data?: Record<string, unknown> | FormData;
  // getToken is usually required, so to make an unauthed request,
  // you explicitly pass in null
  getToken: GetToken | ServerGetToken | null;
};

type FullOptions = RequestOptions & {
  timeout?: number;
};

const IS_SERVER = typeof window === 'undefined';
// NOTE: These are not picked with any special care, don't be afraid to tweak
const DEFAULT_QUERY_TIMEOUT = IS_SERVER ? 10000 : 20000;
const DEFAULT_MUTATION_TIMEOUT = 10000;

/**
 * # request
 * Make an HTTP request with strong defaults and error handling
 *
 * Props:
 * - **method**: HTTP method `(GET, POST, PUT, PATCH, DELETE)`
 * - **path**: Usually a relative path for `dapi`, if a full URL is provided, it will be used as is
 * - **data**: `Object` or `FormData` to send in the request
 * - **token**: Optional token to send in the `Authorization` header
 * - **getToken**: Optional function to get a token
 * - **timeout**: Optional, queries default to 2s/10s for server/client, mutations default to 10s
 *
 * @param {FullOptions} options - The request properties
 * @returns {Promise<Return>} The parsed response
 */
export function request<Return>({
  timeout,
  ...requestOptions
}: FullOptions): Promise<Return> {
  const method = requestOptions.method?.toUpperCase() ?? 'GET';
  const defaultTimeout =
    method === 'GET' ? DEFAULT_QUERY_TIMEOUT : DEFAULT_MUTATION_TIMEOUT;

  const defaultedTimeout = timeout ?? defaultTimeout;

  const promise = rawRequest<Return>(requestOptions);

  if (!defaultedTimeout || defaultedTimeout === -1) {
    return promise;
  }

  let timer: NodeJS.Timeout | undefined;
  const mainPromise = promise.finally(() => {
    if (timer) {
      clearTimeout(timer);
    }
  });
  const timeoutPromise = new Promise<Return>((_, reject) => {
    timer = setTimeout(() => {
      reject(
        new Error(
          `${method} timed out after ${defaultedTimeout}ms - ${requestOptions.path}`,
        ),
      );
    }, defaultedTimeout);
  });

  return Promise.race([mainPromise, timeoutPromise]);
}

async function rawRequest<Return>({
  method = 'GET',
  path,
  data,
  getToken,
}: RequestOptions): Promise<Return> {
  const url = new URL(
    path,
    process.env['NEXT_PUBLIC_DASHBOARD_API'],
  ).toString();

  if (typeof getToken === 'undefined') {
    const prefetchError = new Error(`Missing prefetch: ${path}`);
    prefetchError.stack = undefined;
    throw prefetchError;
  }

  let derivedToken;
  if (getToken !== null) {
    derivedToken = await getToken();
  }

  const headers: HeadersInit = {};
  if (derivedToken) {
    headers['Authorization'] = derivedToken;
  }

  let body;
  if (data instanceof FormData) {
    body = data;
  } else {
    headers['Content-Type'] = 'application/json';
    body = method === 'GET' ? null : JSON.stringify(data);
  }

  const res = await fetch(url, { body, cache: 'no-store', headers, method });

  if (!res.ok) {
    let body;

    try {
      body = await res.json();
    } catch {
      body = {}; // We don't need to worry about it here
    }

    throw new HTTPError(
      res.statusText || res.status.toString() || 'An error occurred',
      res.status,
      body.errors,
      { path },
    );
  }

  return res.status === 204
    ? Promise.resolve({})
    : res.json().catch(() => Promise.resolve({}));
}
