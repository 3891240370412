import { IconProps } from '@/app/components/Icon';

export function ArrowRightIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path d='M11 13.5L15 10.0001M15 10.0001L11 6.5M15 10.0001L5 10.0001' />
        </>
      ) : (
        <>
          <path d='M8.66667 11L12 8M12 8L8.66667 5M12 8H4' />
        </>
      )}
    </>
  );
}
