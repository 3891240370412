import { useUser } from '@clerk/nextjs';

export default function useClerkStaff() {
  const { user } = useUser();

  const hasClerkComEmail =
    user?.primaryEmailAddress?.emailAddress.endsWith('@clerk.com');
  const hasClerkDevEmail =
    user?.primaryEmailAddress?.emailAddress.endsWith('@clerk.dev');
  const hasVerifiedEmail =
    user?.primaryEmailAddress?.verification.status === 'verified';

  const isStaff = (hasClerkComEmail || hasClerkDevEmail) && hasVerifiedEmail;

  return isStaff ?? false;
}
