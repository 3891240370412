'use client';

import { ApplicationMinimal } from '@/app/api/types/applications';
import { Icon } from '@/app/components/Icon';
import { NavigationBreadcrumbButton } from '@/app/components/NavigationBreadcrumbButton';
import { to } from '@/app/utils/url';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { cx } from 'cva';
import Image from 'next/image';
import Link from 'next/link';
import { useParams, usePathname } from 'next/navigation';
import React from 'react';
import { useInView } from 'react-intersection-observer';
// TODO: Remove this when we remove the pages router
import { ApplicationMinimal as PagesApplicationMinimal } from '@/dapi/applications/types';

type Props = {
  application: ApplicationMinimal | PagesApplicationMinimal;
  applications: Array<ApplicationMinimal | PagesApplicationMinimal>;
  track: (message: string, properties?: any) => void;
};

export function ApplicationSwitcher({
  application,
  applications,
  track,
}: Props) {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <NavigationBreadcrumbButton>
          <span className='flex items-center gap-1.5'>
            <span className='truncate'>{application.name}</span>
            <span
              className={cx(
                'rounded-sm px-[0.3125rem] py-px text-2xs font-medium transition',
                application.subscription_plan_title === 'Free'
                  ? 'bg-gray-400 text-gray-1100 group-hover:bg-gray-500'
                  : 'border border-sky-400 bg-sky-300 text-sky-950',
              )}
            >
              {application.subscription_plan_title}
            </span>
          </span>
        </NavigationBreadcrumbButton>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          onCloseAutoFocus={e => e.preventDefault()}
          align='start'
          sideOffset={4}
          className={cx(
            'relative w-[16.875rem] rounded-2xl border border-black/5 bg-gray-200 shadow-lg dark:border-black/5 dark:bg-gray-200 dark:shadow-black/20',
            'before:absolute before:inset-0 before:rounded-inherit before:shadow-[inset_0_1px_0,inset_0_1px_0,inset_0_0_0_1px] before:shadow-white/2 before:transition',
          )}
        >
          <Content
            application={application}
            applications={applications}
            track={track}
          />
          <DropdownMenu.Item
            className='focus:outline-none'
            onSelect={() => {
              track(
                'Dashboard_Applications_New Application Selector Button Clicked',
                {
                  location: 'Sidebar',
                  surface: 'Dashboard',
                },
              );
            }}
          >
            <Link
              href='/apps/new'
              data-analytics='create-application'
              className='flex items-center gap-3.5 px-3.5 py-2 text-gray-1100 hover:text-gray-1200'
            >
              <div className='p-0.5 text-gray-1100'>
                <svg viewBox='0 0 26 26' className='size-4 fill-none'>
                  <path
                    d='M13 25C19.6274 25 25 19.6274 25 13C25 6.37258 19.6274 1 13 1C6.37258 1 1 6.37258 1 13C1 19.6274 6.37258 25 13 25Z'
                    fill='currentColor'
                    fillOpacity='0.1'
                    stroke='currentColor'
                    strokeOpacity='0.4'
                    strokeDasharray='2 2'
                  />
                  <path
                    d='M13.7715 8.62857C13.7715 8.42397 13.6902 8.22776 13.5455 8.08308C13.4008 7.93842 13.2046 7.85714 13 7.85714C12.7955 7.85714 12.5992 7.93842 12.4545 8.08308C12.3099 8.22776 12.2286 8.42397 12.2286 8.62857V12.2286H8.62861C8.42401 12.2286 8.22779 12.3098 8.08312 12.4545C7.93846 12.5992 7.85718 12.7954 7.85718 13C7.85718 13.2046 7.93846 13.4008 8.08312 13.5455C8.22779 13.6902 8.42401 13.7714 8.62861 13.7714H12.2286V17.3714C12.2286 17.576 12.3099 17.7722 12.4545 17.9169C12.5992 18.0616 12.7955 18.1429 13 18.1429C13.2046 18.1429 13.4008 18.0616 13.5455 17.9169C13.6902 17.7722 13.7715 17.576 13.7715 17.3714V13.7714H17.3715C17.576 13.7714 17.7723 13.6902 17.9169 13.5455C18.0616 13.4008 18.1429 13.2046 18.1429 13C18.1429 12.7954 18.0616 12.5992 17.9169 12.4545C17.7723 12.3098 17.576 12.2286 17.3715 12.2286H13.7715V8.62857Z'
                    fill='currentColor'
                  />
                </svg>
              </div>

              <span>Create application</span>
            </Link>
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}

function Content({ application, applications, track }: Props) {
  const pathname = usePathname()!;
  const params = useParams<
    { applicationId: string; instanceId: string } & Record<string, string>
  >()!;
  const activeApplicationId = application?.id;

  const { applicationId, instanceId, ...extraParams } = params;
  const hasExtraParams = Object.keys(extraParams).length > 0;

  const { ref: scrollTopRef, inView: scrollTopInView } = useInView({
    initialInView: true,
  });
  const { ref: scrollBottomRef, inView: scrollBottomInView } = useInView({
    initialInView: false,
  });

  return (
    <>
      <div className='rounded-t-inherit border-b border-gray-400 bg-white p-3 dark:bg-gray-300'>
        <div className='flex items-center gap-3'>
          {application?.favicon_image_url ? (
            <Image
              src={application?.favicon_image_url}
              alt={application?.name}
              height={24}
              width={24}
              className='size-6 rounded-md object-cover'
            />
          ) : application?.logo_image_url ? (
            <Image
              src={application?.logo_image_url}
              alt={application?.name}
              height={24}
              width={24}
              className='size-6 rounded-md object-cover'
            />
          ) : (
            <div className='flex size-6 items-center justify-center rounded bg-gray-300 p-1 text-gray-1100'>
              <Icon name='application' className='fill-none' />
            </div>
          )}

          <span className='flex-grow truncate text-gray-1200'>
            {application?.name}
          </span>

          <Link
            href={to(pathname, { page: '/settings' })}
            onClick={() => {
              document.dispatchEvent(
                new KeyboardEvent('keydown', { key: 'Escape' }),
              );
            }}
            className='transition-color grid size-6 place-items-center rounded-md text-gray-1100 shadow-[0px_0px_0px_1px_rgba(0,0,0,0.07),0px_2px_3px_-1px_rgba(0,0,0,0.08),0px_1px_0px_0px_rgba(0,0,0,0.02)] hover:text-gray-1200 dark:bg-gray-500 dark:hover:bg-gray-600'
          >
            <svg viewBox='0 0 16 16' className='size-4 fill-current'>
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M6.559 2.536A.667.667 0 0 1 7.212 2h1.574a.667.667 0 0 1 .653.536l.22 1.101c.466.178.9.429 1.287.744l1.065-.36a.667.667 0 0 1 .79.298l.787 1.362a.666.666 0 0 1-.136.834l-.845.742c.079.492.079.994 0 1.486l.845.742a.666.666 0 0 1 .137.833l-.787 1.363a.667.667 0 0 1-.791.298l-1.065-.36c-.386.315-.82.566-1.286.744l-.22 1.101a.666.666 0 0 1-.654.536H7.212a.666.666 0 0 1-.653-.536l-.22-1.101a4.664 4.664 0 0 1-1.287-.744l-1.065.36a.666.666 0 0 1-.79-.298L2.41 10.32a.667.667 0 0 1 .136-.834l.845-.743a4.7 4.7 0 0 1 0-1.485l-.845-.742a.667.667 0 0 1-.137-.833l.787-1.363a.667.667 0 0 1 .791-.298l1.065.36c.387-.315.821-.566 1.287-.744l.22-1.101ZM7.999 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z'
              />
            </svg>
          </Link>
        </div>
      </div>

      {applications && applications.length > 1 && (
        <DropdownMenu.Group className='divide-y divide-gray-400 overflow-hidden rounded-b-lg border-b border-black/5 bg-white shadow-[0_1px_0_-1px,0_1px_1px_-0.5px] shadow-black/3 dark:bg-gray-300'>
          <div className='relative'>
            <span
              aria-hidden
              className={cx(
                'pointer-events-none absolute left-0 top-0 z-1 h-12 w-full bg-gradient-to-b from-white to-transparent transition-opacity',
                {
                  'opacity-0': scrollTopInView,
                },
              )}
            />

            <span
              aria-hidden
              className={cx(
                'pointer-events-none absolute bottom-0 left-0 z-1 h-12 w-full bg-gradient-to-t from-white to-transparent transition-opacity',
                {
                  'opacity-0': scrollBottomInView,
                  hidden: applications.length < 12,
                },
              )}
            />

            <div className='max-h-96 overflow-y-auto'>
              <div
                aria-hidden
                ref={scrollTopRef}
                className='block h-0 w-full'
              />
              {applications
                ?.filter(app => app.id !== activeApplicationId)
                .map(application => (
                  <DropdownMenu.Item
                    asChild
                    key={application.id}
                    className='group last:rounded-b-lg'
                    onSelect={event => {
                      event.preventDefault();
                      const activeInstance = application.instances.find(
                        ({ id }) => id === params?.instanceId,
                      );
                      track('Dashboard_Top Nav_Application Menu Item Clicked', {
                        surface: 'Dashboard',
                        location: 'Top Nav',
                        environmentType: activeInstance
                          ? activeInstance.environment_type
                          : '',
                        clickedOn: application.name,
                      });
                    }}
                  >
                    <Link
                      className='flex cursor-pointer items-center gap-4 px-4 py-2 text-gray-1100 hover:bg-gray-200 hover:text-gray-1200 dark:hover:bg-gray-400/60'
                      href={to(pathname, {
                        // If we are currently on a page that has extra
                        // url-params besides applicationId and instanceId,
                        // that page wont exist on the app we are switching to,
                        // so we navigate to the Overview instead
                        page: hasExtraParams ? '/' : undefined,
                        applicationId: application.id,
                        instanceId: application.instances[0].id,
                      })}
                    >
                      {application?.logo_image_url ? (
                        <Image
                          src={application?.logo_image_url}
                          alt={application?.name}
                          height={16}
                          width={16}
                          className='rounded-sm'
                        />
                      ) : (
                        <Icon
                          name='application'
                          size='sm'
                          className='size-4 text-gray-1100'
                        />
                      )}

                      <span className='flex-1 truncate'>
                        {application.name}
                      </span>

                      <svg
                        viewBox='0 0 16 16'
                        className='invisible size-4 fill-current text-gray-1000 group-hover:visible'
                      >
                        <path d='M9.53 4.47a.75.75 0 0 0-1.06 1.06l1.72 1.72H4a.75.75 0 0 0 0 1.5h6.19l-1.72 1.72a.75.75 0 1 0 1.06 1.06l3-3a.75.75 0 0 0 0-1.06l-3-3Z' />
                      </svg>
                    </Link>
                  </DropdownMenu.Item>
                ))}

              <div
                aria-hidden
                ref={scrollBottomRef}
                className='block h-0 w-full'
              />
            </div>
          </div>
        </DropdownMenu.Group>
      )}
    </>
  );
}
