// Note: This file should NOT have 'use client' on top, but also not use
// any server only features. It's shared in both environments and should
// render as a Server Component in app router, and as a normal one in pages.

import { HelpCenter } from '@/app/components/HelpCenter';
import { LogoIcon } from '@/app/components/LogoIcon';
import { UserButton } from '@/app/components/UserButton';
import { cx } from 'cva';
import Link from 'next/link';
import React from 'react';
import { NavigationBreadcrumbButton } from './NavigationBreadcrumbButton';
import { Skeleton } from './ui/Skeleton';

type Props = {
  switchers: React.ReactNode | null;
  primaryNavigation?: React.ReactNode;
};
export function ApplicationHeader({ switchers, primaryNavigation }: Props) {
  return (
    <>
      <header className='bg-gray-100'>
        <div className='flex items-center justify-between gap-3 overflow-x-auto border-b bg-gray-100 py-2 pl-2 pr-4 md:pl-3.5 md:pr-5'>
          <div className='flex items-center gap-1'>
            <Link
              href='/'
              className={cx(
                'relative p-2 outline-none',
                'after:pointer-events-none after:absolute after:left-1/2 after:top-1/2 after:size-9 after:-translate-x-1/2 after:-translate-y-1/2 after:rounded-full after:border-2 after:border-blue after:opacity-0',
                'focus-visible:after:opacity-100',
              )}
              aria-label='Go to dashboard home page'
            >
              <LogoIcon />
            </Link>

            {switchers}
          </div>

          <div className='flex items-center gap-3.5'>
            <HelpCenter />
            <UserButton />
          </div>
        </div>
      </header>

      {primaryNavigation}
    </>
  );
}
export function PathDivider() {
  return (
    <svg
      width='8'
      height='16'
      viewBox='0 0 8 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='max-md:hidden'
    >
      <path
        d='M7 1L1 15'
        className='stroke-gray-600'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

// Skeleton
export const ApplicationHeaderSkeleton = ({
  switchers = true,
  primaryNavigation = true,
}: {
  switchers?: boolean;
  primaryNavigation?: boolean;
}) => (
  <div className='isolate flex-1'>
    <header className='bg-gray-100'>
      <div className='flex items-center justify-between gap-6 border-b py-2 pl-2 pr-4 md:pl-3.5 md:pr-5'>
        <div className='flex items-center md:gap-0.5'>
          {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
          <a
            href='/'
            className={cx(
              'relative p-2 outline-none',
              'after:pointer-events-none after:absolute after:left-1/2 after:top-1/2 after:size-9 after:-translate-x-1/2 after:-translate-y-1/2 after:rounded-full after:border-2 after:border-blue after:opacity-0',
              'focus-visible:after:opacity-100',
            )}
            aria-label='Go to dashboard home page'
          >
            <LogoIcon />
          </a>

          {switchers ? (
            <div className='isolate flex items-center gap-1'>
              <NavigationBreadcrumbButton>
                <Skeleton width={16} height={16} className='mr-1 size-4' />
                <Skeleton width={80} height={16} rounded='sm' />
              </NavigationBreadcrumbButton>
              <PathDivider />
              <NavigationBreadcrumbButton>
                <div className='flex items-center gap-1.5'>
                  <Skeleton width={80} height={16} rounded='sm' />
                  <Skeleton width={28} height={16} rounded='sm' />
                </div>
              </NavigationBreadcrumbButton>
              <PathDivider />
              <NavigationBreadcrumbButton>
                <div className='flex items-center gap-2.5'>
                  <span className='block size-1.5 shrink-0 rounded-full bg-gray shadow-[0_0_0_3px] shadow-gray/25' />
                  <Skeleton width={72} height={16} rounded='sm' />
                </div>
              </NavigationBreadcrumbButton>
            </div>
          ) : null}
        </div>

        <div className='flex items-center gap-3.5'>
          <div className='grid size-8 place-items-center'>
            <Skeleton width={20} height={20} rounded='sm' />
          </div>
          <Skeleton width={28} height={28} rounded='full' />
        </div>
      </div>
    </header>

    <div className='ml-1 flex h-12 w-full *:m-3.5'>
      {primaryNavigation ? (
        <>
          <Skeleton width={60} height={18} rounded='sm' />
          <Skeleton width={38} height={18} rounded='sm' />
          <Skeleton width={90} height={18} rounded='sm' />
          <Skeleton width={64} height={18} rounded='sm' />
        </>
      ) : null}
    </div>
  </div>
);
