import { IconProps } from '@/app/components/Icon';

export function ArrowLeftIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path d='M9 6.5L5 9.99994M5 9.99994L9 13.5M5 9.99994L15 9.99994' />
        </>
      ) : (
        <>
          <path d='M7.33333 5L4 8M4 8L7.33333 11M4 8L12 8' />
        </>
      )}
    </>
  );
}
