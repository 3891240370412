import { useQuery } from '@hooks/useQuery';

import type { APIQueryHook } from '@typeDefs/API';
import type {
  InstanceExtended,
  Theme,
  InstanceKey,
  ActiveDisplayConfig,
  WebhookSettings,
  InstanceDeployStatus,
  OrganizationSettings,
  DisplayConfigUrls,
} from './types';

import {
  getWebhookSettings,
  getDisplayConfig,
  getInstance,
  getTheme,
  getInstanceKeys,
  getDeployStatus,
  getOrganizationSettings,
  getDisplayConfigUrls,
} from './api';
import { buildInstancePath } from './utils';

export const useFetchInstance: APIQueryHook<InstanceExtended> = params => {
  const options = params?.options || {};
  return useQuery('', getInstance, options);
};

export const useFetchInstanceKeys: APIQueryHook<InstanceKey[]> = () => {
  const path = buildInstancePath({ action: 'instance_keys' });
  return useQuery(path, getInstanceKeys);
};
export const useFetchTheme: APIQueryHook<Theme> = () => {
  const path = buildInstancePath({ action: 'theme' });
  return useQuery(path, getTheme);
};

export const useFetchDisplayConfig: APIQueryHook<ActiveDisplayConfig> = () => {
  const path = buildInstancePath({ action: 'display_config' });
  return useQuery(path, getDisplayConfig);
};

export const useFetchDisplayConfigUrls: APIQueryHook<
  DisplayConfigUrls
> = params => {
  const options = params?.options || {};
  const path = buildInstancePath({ action: 'display_config/urls' });
  return useQuery(path, getDisplayConfigUrls, { ...options });
};

export const useFetchWebhooksSettings: APIQueryHook<WebhookSettings> = () => {
  const path = buildInstancePath({ action: 'webhooks/svix' });
  return useQuery(path, getWebhookSettings);
};

export const useFetchOrganizationSettings: APIQueryHook<
  OrganizationSettings
> = () => {
  const path = buildInstancePath({ action: 'organization_settings' });
  return useQuery(path, getOrganizationSettings);
};

export type InstanceDeployStatusProps = {
  instanceId: string;
};
export const useFetchDeploymentStatus: APIQueryHook<
  InstanceDeployStatus,
  InstanceDeployStatusProps
> = params => {
  const { options = {}, instanceId = '' } = params;
  const path = buildInstancePath({ action: 'deploy_status' });
  return useQuery(path, getDeployStatus, {
    ...options,
    pathPrefix: `v1/instances/${instanceId}`,
  });
};
