import { DELETE, GET, PATCH, POST } from '@utils/api';

import {
  ApplicationExtended,
  ApplicationMinimal,
  CreateApplicationParams,
  CreateProductionInstance,
  ValidateCloning,
  TransferToOrganizationParams,
} from './types';
import { InstanceExtended } from '@dapi/instances/types';

export const getApplication = ({
  path,
  token,
}: {
  path: string;
  token: string;
}): Promise<ApplicationExtended> => {
  return GET<ApplicationExtended>({ path, token });
};

export const listApplications = ({
  path,
  token,
}: {
  path: string;
  token: string;
}): Promise<ApplicationMinimal[]> => {
  return GET<ApplicationMinimal[]>({ path, token });
};

export const updateApplication = ({
  path,
  token,
  data,
}: {
  path: string;
  token: string;
  data: Partial<ApplicationExtended>;
}): Promise<ApplicationExtended> => {
  return PATCH<ApplicationExtended>({ path, token, data });
};

export const deleteApplication = ({
  path,
  token,
}: {
  path: string;
  token: string;
}): Promise<never> => {
  return DELETE<never>({ path, token, data: {} });
};

export const transferToOrganization = ({
  path,
  token,
  data,
}: {
  path: string;
  token: string;
  data: TransferToOrganizationParams;
}): Promise<never> => {
  return POST<never>({ path, token, data });
};

export const transferToPersonalWorkspace = ({
  path,
  token,
}: {
  path: string;
  token: string;
}): Promise<never> => {
  return POST<never>({ path, token, data: {} });
};

export const uploadImageResource = ({
  path,
  token,
  data,
}: {
  path: string;
  token: string;
  data: FormData;
}): Promise<never> => {
  return POST<never>({ path, token, data });
};

export const createApplication = ({
  path,
  token,
  data,
}: {
  path: string;
  token: string;
  data: Partial<CreateApplicationParams>;
}): Promise<ApplicationExtended> => {
  return POST<ApplicationExtended>({ path, token, data });
};

export const deleteLogo = ({
  path,
  token,
}: {
  path: string;
  token: string;
}): Promise<never> => {
  return DELETE<never>({ path, token, data: {} });
};

export const deleteFavicon = ({
  path,
  token,
}: {
  path: string;
  token: string;
}): Promise<never> => {
  return DELETE<never>({ path, token, data: {} });
};

export const listInstances = ({
  path,
  token,
}: {
  path: string;
  token: string;
}): Promise<InstanceExtended[]> => {
  return GET<InstanceExtended[]>({ path, token });
};

export const createProductionInstance = ({
  path,
  token,
  data,
}: {
  path: string;
  token: string;
  data: CreateProductionInstance;
}): Promise<InstanceExtended> => {
  return POST<InstanceExtended>({ path, token, data });
};

export const validateCloning = ({
  path,
  token,
  data,
}: {
  path: string;
  token: string;
  data: ValidateCloning;
}): Promise<never> => {
  return POST<never>({ path, token, data });
};
