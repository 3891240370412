import { IconProps } from '@/app/components/Icon';

export function XIconPath(props: { size: IconProps['size'] }) {
  return (
    <>
      {props.size === 'base' ? (
        <>
          <path d='M5.75 5.75L14.25 14.25M14.25 5.75L5.75 14.25' />
        </>
      ) : (
        <>
          <path d='M4.75 4.75L8 8M8 8L11.25 11.25M8 8L11.25 4.75M8 8L4.75 11.25' />
        </>
      )}
    </>
  );
}
