'use client';

import { useDropdownStore } from '@/app/components/OrganizationSwitcherStore';
import { Skeleton } from '@/app/components/ui/Skeleton';
import {
  OrganizationSwitcher as ClerkOrganizationSwitcher,
  useOrganization,
  useUser,
} from '@clerk/nextjs';
import { OrganizationResource } from '@clerk/types';
import * as Popover from '@radix-ui/react-popover';
import { Squircle } from '@squircle-js/react';
import { cx } from 'cva';
import Image from 'next/image';
import { PropsWithChildren, useEffect, useState } from 'react';
import { NavigationBreadcrumbButton } from './NavigationBreadcrumbButton';

type Props = PropsWithChildren<{
  initialData?: Pick<OrganizationResource, 'name' | 'imageUrl'> | null;
}>;
export function OrganizationSwitcher({ initialData, children }: Props) {
  const { organization, isLoaded } = useOrganization();
  const { user } = useUser();

  const { open, setOpen } = useDropdownStore();
  const [invitationsCount, setInvitationsCount] = useState(0);

  const image =
    (!isLoaded
      ? initialData?.imageUrl
      : organization?.imageUrl || user?.imageUrl) ?? '';

  const name =
    (!isLoaded ? initialData?.name : organization?.name) || 'Personal Account';

  const returnUrl = () => {
    setOpen(false);
    return '/?clear_last_active=true';
  };

  useEffect(() => {
    const fetchInvitations = async () => {
      const invitations = await user?.getOrganizationInvitations({
        status: 'pending',
      });

      if (invitations) {
        setInvitationsCount(invitations.total_count);
      }
    };

    void fetchInvitations();
  }, [user]);

  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <Popover.Trigger asChild>
        <NavigationBreadcrumbButton>
          <span className='relative isolate'>
            {image && (
              <Squircle
                cornerRadius={4}
                cornerSmoothing={1}
                className='z-10 mr-1 size-4 overflow-hidden'
              >
                <Image
                  priority
                  src={image}
                  alt={organization ? 'Organization logo' : 'User avatar'}
                  width={32}
                  height={32}
                />
              </Squircle>
            )}

            <Skeleton
              width={16}
              height={16}
              className='absolute inset-0 -z-10 mr-1 size-4 overflow-hidden'
            />

            {invitationsCount > 0 && (
              <span
                className={cx(
                  'absolute -left-2.5 -top-2.5 isolate z-20 min-w-[1.1875rem] animate-fade-in rounded-full bg-gray-100 px-1 py-sm font-number text-2xs font-semibold text-white transition',
                  'before:absolute before:inset-xs before:-z-1 before:rounded-inherit before:bg-purple-900 before:shadow-md before:shadow-purple-1100/40',
                )}
              >
                {invitationsCount}
              </span>
            )}
          </span>
          <span className='truncate'>{name}</span>
        </NavigationBreadcrumbButton>
      </Popover.Trigger>

      <ClerkOrganizationSwitcher
        __experimental_asProvider
        afterSelectPersonalUrl={returnUrl}
        afterSelectOrganizationUrl={returnUrl}
        afterCreateOrganizationUrl={returnUrl}
        // TODO: Why this is the only `after...` that doesn't accept a function?
        afterLeaveOrganizationUrl='/?clear_last_active=true'
        skipInvitationScreen={true}
        appearance={{
          layout: {
            shimmer: false,
          },
          elements: {
            avatarBox: 'size-4 bg-transparent rounded-[0.3125rem] mr-1',

            userPreviewMainIdentifier: '[&>span]:text-base',
            userPreview: 'gap-1',
            userPreviewMainIdentifier__personalWorkspace:
              'font-book text-gray-1200',

            organizationPreview: 'p-0 gap-1',
            organizationPreviewMainIdentifier:
              'text-base font-book text-gray-1200',
            organizationPreviewMainIdentifier__organizationSwitcherListedOrganization:
              'font-book',
            organizationPreviewSecondaryIdentifier:
              'text-gray-1100 text-2xs leading-[0.8em] relative top-px',
            organizationSwitcherPopoverActionButton: 'min-h-min',
            organizationSwitcherPopoverActionButtonIconBox: 'w-auto basis-auto',
            organizationSwitcherPopoverActionButtonIcon__createOrganization:
              'size-6 scale-[1.25]',
            organizationSwitcherPopoverActionButton__createOrganization:
              'py-1.5 pl-2 pr-2.5 [font-size:inherit] hover:text-gray-1200 gap-1.5 text-gray-1100 dark:[border-top-color:theme(colors.gray.400)_!important] dark:hover:bg-gray-400',
            organizationSwitcherPopoverActionButton__manageOrganization:
              'text-gray-1100 dark:bg-gray-500 dark:hover:bg-gray-600 hover:text-gray-1200',
            organizationSwitcherPopoverActions:
              '[&>:first-child]:p-3 dark:[&>div]:[border-top-color:theme(colors.gray.400)_!important]',
            organizationSwitcherPopoverCard:
              'w-[18.75rem] -translate-y-1 rounded-[0.875rem] [animation:none] border border-black/5 bg-white dark:bg-gray-400 shadow-lg dark:shadow-black/20',
            organizationSwitcherPopoverFooter:
              '[&>div]:p-3 bg-gray-200 text-sm dark:bg-gray-200 bg-[image:none] [&_p]:text-gray-1000 [&_p]:leading-none [&_a]:text-gray-1000 shadow-[inset_0_0_0_1px_rgb(255_255_255/0.01)] rounded-b-[0.8125rem]',
            organizationSwitcherPopoverMain:
              'bg-white border-b dark:bg-gray-300 shadow-sm shadow-black/[0.02] dark:shadow-black/[0.04] relative before:absolute before:pointer-events-none before:inset-0 dark:before:shadow-[inset_0_1px_0_rgb(255_255_255/0.04),inset_0_0_0_1px_rgb(255_255_255/0.02)] before:rounded-t-[0.8125rem] before:rounded-b-[0.5rem]',
            organizationSwitcherPreviewButton:
              'px-3 py-2 [&>svg]:text-gray-1000 *:text-gray-1100 *:hover:text-gray-1200 [&>svg]:hover:text-gray-1000 dark:[border-top-color:theme(colors.gray.400)_!important] dark:hover:bg-gray-400/60',
            // organizationSwitcherTrigger:
            //   'group py-[0.3125rem] pl-2.5 pr-2 hover:border-black/5 hover:bg-white hover:shadow hover:shadow-black/2 relative isolate rounded border border-transparent font-book transition data-[open]:bg-white data-[open]:border-black/5 data-[open]:shadow data-[open]:shadow-black/2 [&_.cl-organizationPreview]:data-[open]:after:opacity-55 [&_.cl-userPreview]:data-[open]:after:opacity-55',
            // organizationSwitcherTriggerIcon: 'hidden',
            organizationSwitcherPopoverInvitationActionsBox: 'px-3 py-1.5',
            organizationSwitcherPopoverInvitationActions: 'py-1.5 bg-gray-100',
            organizationSwitcherInvitationAcceptButton: 'bg-white',

            notificationBadge:
              'bg-purple text-xs font-number font-semibold min-w-4 px-1 animate-fade-in -translate-x-1 translate-y-1',
          },
        }}
      >
        {children}

        <Popover.Portal>
          <Popover.Content
            align='start'
            sideOffset={7}
            className='relative before:absolute before:inset-0 before:rounded-inherit before:shadow-[inset_0_1px_0,inset_0_1px_0,inset_0_0_0_1px] before:shadow-white/2 before:transition'
          >
            <ClerkOrganizationSwitcher.__experimental_Outlet
              __experimental_asStandalone
            />
          </Popover.Content>
        </Popover.Portal>
      </ClerkOrganizationSwitcher>
    </Popover.Root>
  );
}
