'use client';

import { useTypedParams } from '@/app/hooks/useTypedParams';
import { findApplication, findInstance } from '@/app/utils/url';
import { useAuth } from '@clerk/nextjs';
import { useSuspenseQuery } from '@tanstack/react-query';
import { redirect } from 'next/navigation';
import { applicationsQuery } from './applications';
import { ApplicationMinimal } from './types/applications';
import { Instance } from './types/instances';

export const useMinimalApplication = (applicationId: string) => {
  const { sessionId, orgId, getToken } = useAuth();

  const { data } = useSuspenseQuery({
    // The basic info for each application already exists on this query which
    // is prefetched in the root layout and is always available, so we use that
    // to avoid extra requests when we don't need them
    ...applicationsQuery(sessionId!, orgId, { getToken }),
    select: applications => {
      return findApplication(applications, applicationId);
    },
  });

  return data;
};

export const useMinimalInstance = (
  applicationId: string,
  instanceId: string,
) => {
  const { sessionId, orgId, getToken } = useAuth();

  const { data } = useSuspenseQuery({
    // The basic info for each instance already exists on this query which
    // is prefetched in the root layout and is always available, so we use that
    // to avoid extra requests when we don't need them
    ...applicationsQuery(sessionId!, orgId, { getToken }),
    select: applications => {
      const application = findApplication(applications, applicationId);
      return application ? findInstance(application, instanceId) : undefined;
    },
  });

  return data;
};

/**
 * This returns the ApplicationMinimal part of the current application.
 *
 * If you need the full ApplicationExtended version, use the extendedApplicationQuery instead, don't forget to also prefetch it.
 *
 * See getCurrent.server.ts if you need to grab this in a server componment
 */
export const useCurrentApplication = (): ApplicationMinimal => {
  const { applicationId } = useTypedParams();
  const application = useMinimalApplication(applicationId);

  if (!application) {
    redirect('/');
  }

  return application;
};

/**
 * This returns the base Instance part of the current Instance.
 *
 * If you need the full InstanceExtended version, use the extendedInstanceQuery instead, don't forget to also prefetch it.
 *
 * See getCurrent.server.ts if you need to grab this in a server componment
 */
export const useCurrentInstance = (): Instance => {
  const { applicationId, instanceId } = useTypedParams();
  const instance = useMinimalInstance(applicationId, instanceId);

  if (!instance) {
    redirect('/');
  }

  return instance;
};
