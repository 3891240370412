import React from 'react';

import { useFetchApplication } from '@dapi/applications/queries';
import { ApplicationExtended } from '@dapi/applications/types';

import { useLocation } from '@hooks/useLocation';
import { QueryResponse, PartialQueryResponse } from '@typeDefs/API';

export type ApplicationContextType = QueryResponse<ApplicationExtended>;
export type PartialApplicationContextType =
  PartialQueryResponse<ApplicationExtended>;

export const ApplicationContext =
  // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
  React.createContext<ApplicationContextType>(null);

export const useApplicationContext = (): ApplicationContextType => {
  const context = React.useContext(ApplicationContext);

  if (!context) {
    throw new Error(
      'useApplicationContext must be used within a ApplicationContextProvider',
    );
  }
  return context;
};

const Provider = ({ children }: { children: React.ReactNode }) => {
  const { applicationId } = useLocation();

  // @ts-expect-error FIXME: Exposed from enabling StrictNullChecks
  const swr = useFetchApplication({ id: applicationId });

  return (
    <ApplicationContext.Provider value={swr}>
      {children}
    </ApplicationContext.Provider>
  );
};

export default Provider;
