export type Framework = {
  name: string;
  slug: string;
  publishable_key: string;
  secret_key?: string;
  envFile: string;
  url?: string;
  codeSnippet?: boolean;
  logo: string;
  logo_inverted?: string;
  guide_url?: string;
  video_url?: string;
};

const nextJs: Framework = {
  name: 'Next.js',
  slug: 'nextjs',
  publishable_key: 'NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY',
  secret_key: 'CLERK_SECRET_KEY',
  envFile: '.env.local',
  logo: '/assets/logos/nextjs.svg',
  guide_url: 'https://clerk.com/docs/quickstarts/nextjs',
};

const react: Framework = {
  name: 'React',
  slug: 'react',
  publishable_key: 'VITE_CLERK_PUBLISHABLE_KEY',
  envFile: '.env',
  logo: '/assets/logos/react.svg',
  guide_url: 'https://clerk.com/docs/quickstarts/react',
};

const remix: Framework = {
  name: 'Remix',
  slug: 'remix',
  publishable_key: 'CLERK_PUBLISHABLE_KEY',
  secret_key: 'CLERK_SECRET_KEY',
  envFile: '.env',
  logo: '/assets/logos/remix.svg',
  logo_inverted: '/assets/logos/remix-inverted.svg',
  guide_url: 'https://clerk.com/docs/quickstarts/remix',
};

const redwoodjs: Framework = {
  name: 'RedwoodJS',
  slug: 'redwoodjs',
  publishable_key: 'CLERK_PUBLISHABLE_KEY',
  secret_key: 'CLERK_SECRET_KEY',
  envFile: '.env',
  logo: '/assets/logos/redwoodjs.svg',
  guide_url: 'https://clerk.com/docs/references/redwood/overview',
};

const express: Framework = {
  name: 'Express',
  slug: 'express',
  publishable_key: 'CLERK_PUBLISHABLE_KEY',
  secret_key: 'CLERK_SECRET_KEY',
  envFile: '.env',
  logo: '/assets/logos/express.svg',
  guide_url: 'https://clerk.com/docs/quickstarts/express',
};

const expo: Framework = {
  name: 'Expo',
  slug: 'expo',
  publishable_key: 'EXPO_PUBLIC_CLERK_PUBLISHABLE_KEY',
  envFile: '.env',
  logo: '/assets/logos/expo.svg',
  logo_inverted: '/assets/logos/expo-inverted.svg',
  guide_url: 'https://clerk.com/docs/quickstarts/expo',
};

const chrome: Framework = {
  name: 'Chrome Extension',
  slug: 'chrome',
  publishable_key: 'PLASMO_PUBLIC_CLERK_PUBLISHABLE_KEY',
  envFile: '.env.development',
  logo: '/assets/logos/chrome.svg',
  url: 'CLERK_FRONTEND_API',
  guide_url: 'https://clerk.com/docs/quickstarts/chrome-extension',
};

const astro: Framework = {
  name: 'Astro',
  slug: 'astro',
  publishable_key: 'PUBLIC_CLERK_PUBLISHABLE_KEY',
  secret_key: 'CLERK_SECRET_KEY',
  envFile: '.env',
  logo: '/assets/logos/astro.svg',
  logo_inverted: '/assets/logos/astro-inverted.svg',
  guide_url: 'https://clerk.com/docs/quickstarts/astro',
};

const javascript: Framework = {
  name: 'JavaScript',
  slug: 'javascript',
  publishable_key: 'CLERK_PUBLISHABLE_KEY',
  envFile: 'JavaScript',
  codeSnippet: true,
  logo: '/assets/logos/javascript.svg',
  guide_url: 'https://clerk.com/docs/quickstarts/javascript',
};

const tanstack: Framework = {
  name: 'TanStack Start',
  slug: 'tanstack',
  publishable_key: 'VITE_CLERK_PUBLISHABLE_KEY',
  secret_key: 'CLERK_SECRET_KEY',
  envFile: '.env',
  logo: '/assets/logos/tanstack.svg',
  guide_url: 'https://clerk.com/docs/quickstarts/tanstack-start',
};

const reactRouter: Framework = {
  name: 'React Router',
  slug: 'reactrouter',
  publishable_key: 'VITE_CLERK_PUBLISHABLE_KEY',
  secret_key: 'CLERK_SECRET_KEY',
  envFile: '.env',
  logo: '/assets/logos/reactrouter.svg',
  guide_url: 'https://clerk.com/docs/quickstarts/react-router',
};

const vue: Framework = {
  name: 'Vue',
  slug: 'vue',
  publishable_key: 'VITE_CLERK_PUBLISHABLE_KEY',
  envFile: '.env.local',
  logo: '/assets/logos/vue.svg',
  guide_url: 'https://clerk.com/docs/quickstarts/vue',
};

const nuxt: Framework = {
  name: 'Nuxt',
  slug: 'nuxt',
  publishable_key: 'NUXT_PUBLIC_CLERK_PUBLISHABLE_KEY',
  secret_key: 'NUXT_CLERK_SECRET_KEY',
  envFile: '.env',
  logo: '/assets/logos/nuxt.svg',
  guide_url: 'https://clerk.com/docs/quickstarts/nuxt',
};

const fastify: Framework = {
  name: 'Fastify',
  slug: 'fastify',
  publishable_key: 'CLERK_PUBLISHABLE_KEY',
  secret_key: 'CLERK_SECRET_KEY',
  envFile: '.env',
  logo: '/assets/logos/fastify.svg',
  logo_inverted: '/assets/logos/fastify-inverted.svg',
  guide_url: 'https://clerk.com/docs/quickstarts/fastify',
};

export const START_BUILDING_FRAMEWORKS: Framework[] = [
  nextJs,
  react,
  remix,
  astro,
  expo,
  javascript,
  tanstack,
];

export const JS_FRAMEWORKS: Framework[] = [
  // First, we present the frameworks from the "Start Building" section:
  ...START_BUILDING_FRAMEWORKS,
  // Then we proceed to the others in alphabetical order:
  chrome,
  express,
  fastify,
  nuxt,
  reactRouter,
  redwoodjs,
  vue,
];
